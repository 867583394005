function WaitingSpinner({ data = null, message = 'Loading' }) {
  if (!data) {
    return (
      <div className="flex justify-center items-center h-16 w-16">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }
  return <h3 className="text-center mt-4 text-red-600">{message}</h3>;
}

export default WaitingSpinner;
