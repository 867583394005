const arJSON = {
  Aciklama: 'وصف',
  Add: 'يضيف',
  Added: 'اضيف بنجاح.',
  Address: 'عنوان',
  AdiSoyadi: 'الاسم الكنية',
  Adres: 'عنوان',
  Agustos: 'أغسطس',
  Alacak: 'مستحق',
  Alias: 'اسم العرض',
  AliciAdi: 'اسم المستلم',
  AliciKodu: 'رمز المستلم',
  AppMenu: 'تطبيق',
  Arabic: 'عربي',
  AracNo: 'عدد المركبات',
  AracTakip: 'تتبع المركبات',
  AracTakipGumruk: 'الجمارك تتبع المركبات',
  AracTakipYurtDisi: 'تتبع المركبات',
  Aralik: 'كانون الأول',
  AraToplam: 'المجموع الفرعي',
  AuthorizationsDenied: 'تم الكشف عن أنك تحاول الدخول إلى مجال غير مصرح به.',
  Bakiye: 'الرصيد',
  AltBakiye: 'الحساب الفرعي',
  Bekleme: 'انتظر',
  Bildirimler: 'إشعارات',
  BirHataOlustu: 'هناك خطأ ما',
  Borc: 'دين',
  Bosaltma: 'التفريغ',
  BuAy: '1 شهر',
  Bugun: 'اليوم',
  BuHafta: 'هذا الأسبوع',
  BuYil: 'سنة 1',
  ChangePassword: 'تغيير كلمة المرور',
  Cikis: 'مخرج',
  CitizenNumber: 'رقم الجنسية',
  Code: 'رمز',
  ConfirmPassword: 'تأكيد كلمة المرور',
  CouldNotBeVerifyCid: 'فشل التحقق من الهوية.',
  Create: 'الجديد',
  Degistir: 'يتغيرون',
  Delete: 'حذف',
  DeleteConfirm: 'هل انتم متأكدون؟',
  Deleted: 'حذف بنجاح.',
  Depo: 'مستودع',
  Description: 'وصف',
  Dil: 'لسان',
  Doctors: 'طبيب',
  Doviz: 'عملة أجنبية',
  Durum: 'حالة',
  Ekim: 'اكتوبر',
  Email: 'بريد إلكتروني',
  English: 'إنجليزي',
  EpostaAdresi: 'عنوان البريد الالكتروني',
  EvrakNo: 'رقم المستند',
  Eylul: 'سبتمبر',
  FaturaBilgileri: 'معلومات الفواتير',
  FirmaAdi: 'اسم الشركة',
  FullName: 'الاسم الكامل',
  GeciciSifre: 'كلمة مرور مؤقتة',
  Geri: 'خلف',
  German: 'ألمانية',
  Giris: 'تسجيل الدخول',
  GirisEkraninaYonlendiriliyor: 'إعادة التوجيه إلى شاشة تسجيل الدخول',
  GirisYap: 'تسجيل الدخول',
  GonderenAdi: 'اسم المرسل',
  GonderilenHavale: 'الحوالة المرسلة',
  GroupList: 'قائمة المجموعة',
  GroupName: 'أسم المجموعة',
  Groups: 'مجموعات',
  GrupPermissions: 'أذونات المجموعة',
  Gsm: 'جوال',
  Gumruk: 'جمرك',
  GumrukCikis: 'جمارك الخروج',
  Hakkimizda: 'معلومات عنا',
  Hakkinda: 'حول',
  Havale: 'تحويل',
  HavaleHesap: 'حساب التحويل',
  HavaleListesi: 'قائمة الحوالات',
  Haziran: 'يونيه',
  InvalidCode: 'كنت قد دخلت رمز غير صالح!',
  id: 'رقم التسجيل',
  iletisim: 'تواصل',
  iptal: 'إلغاء',
  isim: 'اسم',
  Kampanya: 'عرض',
  KapSayisi: 'عدد الحاوية',
  Kargo: 'شحن',
  Hesap: 'حساب',
  HesapEkstresi: 'كشف حساب',
  UrunListesi: 'قائمة المنتجات',
  UrunTakip: 'تتبع المنتجات',
  Kaydet: 'يحفظ',
  KayitNo: 'رقم التسجيل',
  KayitOl: 'سجل',
  Kg: 'كلغ',
  konum: 'موقع',
  KullaniciAdi: 'اسم المستخدم',
  Kurdish: 'كردي',
  LangCode: 'رمز اللغة',
  LanguageList: 'قائمة اللغات',
  Languages: 'اللغات',
  Login: 'تسجيل الدخول',
  LogList: 'سجل المعاملات',
  M3: 'م 3',
  M3Kg: 'م 3 / كغ',
  mail: 'بريد الالكتروني',
  MalKabul: 'قبول البضائع',
  Management: 'إدارة',
  Mart: 'آذار',
  Mayis: 'يمكن',
  Menu: 'القائمة',
  MevcutSifre: 'كلمة المرور الحالي',
  MobilePhones: 'جوال',
  Name: 'الاسم الأول',
  NameAlreadyExist: 'الكائن الذي تحاول إنشاءه موجود بالفعل.',
  Nisan: 'أبريل',
  NoRecordsFound: 'لا توجد سجلات',
  Notes: 'ملاحظات',
  Ocak: 'كانون الثاني',
  Ok: 'حسنا',
  Okundu: 'اقرأ',
  OkunmamisMesajlar: 'رسائل غير مقروءة',
  Onaylandı: 'وافق',
  OneriSikikayet: 'شكوى اقتراح',
  OperationClaim: 'السلطات التنفيذية',
  OperationClaimExists: 'تصريح التشغيل هذا موجود بالفعل.',
  OperationClaimList: 'قائمة التفويضات',
  Ödendi: 'مدفوع',
  Password: 'كلمة المرور',
  PasswordDigit: 'يجب أن يحتوي على رقم واحد على الأقل!',
  PasswordEmpty: 'لا يمكن أن تكون كلمة المرور فارغة!',
  PasswordError:
    'فشلت مصادقة بيانات الاعتماد و / أو اسم المستخدم و / أو كلمة المرور غير صحيحة.',
  PasswordLength: 'يجب ألا يقل طوله عن 8 أحرف!',
  PasswordLowercaseLetter: 'يجب أن يحتوي على حرف واحد صغير على الأقل!',
  PasswordSpecialCharacter: 'يجب أن يحتوي على رمز واحد على الأقل!',
  PasswordUppercaseLetter: 'يجب أن يحتوي على حرف كبير واحد على الأقل!',
  Permissions: 'أذونات',
  phone1: 'الهاتف 1',
  phone2: 'الهاتف 2',
  phone3: 'الهاتف 3',
  Plaka: 'لوحة الأرقام',
  Profil: 'حساب تعريفي',
  Rapor: 'نقل',
  Required: 'هذه الخانة مطلوبه!',
  Saha: 'حقل',
  Save: 'يحفظ',
  Sehir: 'مدينة',
  SendMobileCode: 'الرجاء إدخال الرمز المرسل إليك في رسالة SMS!',
  SendPassword:
    'تم إرسال كلمة المرور الجديدة الخاصة بك إلى عنوان البريد الإلكتروني الخاص بك.',
  Sifirla: 'إعادة ضبط',
  Sifre: 'كلمة المرور',
  SifreDegistir: 'تغيير كلمة المرور',
  SifreEslesmiyor: 'كلمة السر غير متطابقة',
  SifremiUnuttum: 'لقد نسيت كلمة المرور',
  SifremVar: 'لدي كلمة سر',
  SifrenizDahaOnceGonderildi: 'تم بالفعل إرسال كلمة المرور الخاصة بك',
  SifreTelefonaGonderildi: 'تم إرسال كلمة المرور الخاصة بك إلى الهاتف',
  SifreYenile: 'إعادة تعيين كلمة المرور',
  SifreyiDogrula: 'اكد كلمة المرور',
  Sira: 'ترتيب',
  SmsServiceNotFound: 'تعذر الوصول إلى خدمة الرسائل القصيرة.',
  SoforAdi: 'اسم السائق',
  StokAdi: 'اسم المخزون',
  StringLengthMustBeGreaterThanThree: 'الرجاء إدخال تعبير لا يقل عن 3 أحرف.',
  Subat: 'شهر فبراير',
  SubeListesi: 'قائمة الفروع',
  SuccessfulLogin: 'تم تسجيل الدخول إلى النظام بنجاح.',
  TakipNo: 'عدد تتبع',
  TalimatListesi: 'قائمة التعليمات',
  Tarih: 'تاريخ',
  TarihTutar: 'التاريخ - المبلغ',
  Telefon: 'هاتف',
  Telefon1: 'الهاتف 1',
  Telefon2: 'الهاتف 2',
  TelefonVeyaEmail: 'الهاتف أو البريد الإلكتروني',
  Temmuz: 'يوليو',
  Teslim: 'التسليم الخاص بي',
  Tipi: 'اكتب',
  title: 'لقب',
  TokenProviderException: 'لا يمكن أن يكون موفر الرمز المميز فارغًا!',
  TranslateList: 'قائمة ترجمة اللغة',
  TranslateWords: 'ترجمات اللغة',
  TrueButCellPhone: 'المعلومات صحيحة. الهاتف الخليوي مطلوب.',
  TumunuGoruntule: 'مشاهدة الكل',
  Turkish: 'اللغة التركية',
  Tutar: 'مقدار',
  Ulke: 'دولة',
  Unknown: 'مجهول!',
  Unvan: 'لقب',
  Update: 'تحديث',
  Updated: 'تم التحديث بنجاح.',
  UserList: 'قائمة المستخدم',
  UserNotFound:
    'فشل التحقق من بيانات الاعتماد. الرجاء استخدام شاشة التسجيل الجديدة.',
  Users: 'المستخدمون',
  UsersClaims: 'تصاريح المستخدم',
  UsersGroups: 'مجموعات المستخدمين',
  UyeOl: 'اشتراك',
  Value: 'قيمة',
  VergiDairesi: 'مكتب الضرائب',
  VergiNumarası: 'الرقم الضريبي',
  VerifyCid: 'تم التحقق من الهوية.',
  WrongCitizenId: 'رقم الجنسية غير موجود في نظامنا. الرجاء إنشاء تسجيل جديد!',
  YeniSifre: 'كلمة سر جديدة',
  Yukleme: 'جار التحميل',
  ZatenKayitli: 'مسجل بالفعل',
  HesabiSil: 'حذف الحساب',
  TakipEt: 'تتبع',
  DepoBakiye: 'رصيد المستودع',
  Detay: 'التفاصيل',
  PaketSayisi: 'الحزم',
  HavaleBakiye: 'رصيد التحويل',
  NakitOdeme: 'الدفع النقدي',
  NakliyeOdeme: 'الدفع اللوجستي',
  KomisyonOdeme: 'الدفع بالعمولة',
  KasapHesap: 'حساب الجزار',
  EvHesap: 'حساب المنزل',
  BankaDinarOdeme: 'الدفع بالدينار',
  MusteriKodu: 'رقم المستخدم',
  UserCode: 'رقم المستخدم',
  AcountDelete: 'حذف الحساب',
  Location: 'موقع',
  Yes: 'نعم',
  No: 'لا',
  Warning: 'تحذير',
  RecordLocationInformation: 'تسجيل معلومات الموقع',
  DoYouWantToDeleteTheAccount: 'هل تريد حذف الحساب؟',
  GetAddressWithLocation: 'الحصول على العنوان مع الموقع',
  LocationInformation: 'معلومات الموقع',
  LocationInformationRecorded: 'تم تسجيل معلومات الموقع',
  LocationInformationNotRecorded: 'لم يتم تسجيل معلومات الموقع',
  LocationSaveInformation: 'حفظ معلومات الموقع'
};

export default arJSON;
