import Layout from '../Layouts';
import useMenu from '../../features/hooks/useMenu';
import './mainpage.css';
import { NavLink } from 'react-router-dom';

function MainPage() {
  const { currentMenus, selectedComponentHandler } = useMenu();

  function navClickHandler(e) {
    selectedComponentHandler(e.currentTarget.value);
  }

  const hasWindow = typeof window !== 'undefined';
  const width = hasWindow ? window.innerWidth : null;

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row gx-4">
          {currentMenus.map((menu) => {
            if (!menu.isShowMenu) {
              return null;
            }

            return (
              <NavLink
                to={menu.to}
                key={menu.name}
                value={menu.id}
                className="p-1 col-4 col-lg-2 btn text-white gl-fs-6"
                onClick={(e) => navClickHandler(e)}>
                <div className="mb-0 bg-white text-secondary gl-card-decorative w-100 h-100 rounded shadow gl-place-center p-3 mainpage-grid-container gl-shadow-bottom main-menu-button">
                  <ion-icon size="large" name={menu.icon}></ion-icon>
                  {(() => {
                    if (
                      menu.name.length > 0 &&
                      menu.name.indexOf(' ') === -1 &&
                      width < 600
                    ) {
                      return (
                        <p className="m-0 mainpage-text">
                          {menu.name.substr(0, 9) + '..'}
                        </p>
                      );
                    } else {
                      return <p className="m-0 mainpage-text">{menu.name}</p>;
                    }
                  })()}
                </div>
              </NavLink>
            );
          })}
        </div>
      </div>
    </Layout>
  );
}

export default MainPage;
