import { Route, Routes } from 'react-router-dom';
import ForgetPassword from '../content/Auth/ForgetPassword';
import Login from '../content/Auth/Login';
import Register from '../content/Auth/Register';
import ChangePassword from '../content/Auth/ChangePassword';
import RequireAuth from './RequireAuth';

const Auth = () => {
  return (
    <Routes>
      {/* Giriş Sayfası */}
      <Route path="login" element={<Login />} />
      {/* Telefonla Giriş */}
      {/* Şifre Değişikliği */}
      <Route
        path="changePassword"
        element={
          <RequireAuth>
            <ChangePassword />
          </RequireAuth>
        }
      />
      {/* Kayıt Ol */}
      <Route path="register" element={<Register />} />
      {/* Şifre Unutma */}
      <Route path="forgetPassword" element={<ForgetPassword />} />
    </Routes>
  );
};

export default Auth;
