import useMessage from '../../features/hooks/useMessage';
import axios from '../../utils/axios';

export default function useNotificationApiContext() {
    const { messageBox } = useMessage();

    const GetNotificationList = async () => {
        try {
            const response = await axios.get('/api/Cargo/GetNotificationList');
            const data = response.data.data;
            return data
        } catch (err) {
            messageBox(err, "error");
        }
    };
    const SetNotification = async () => {
        try {
            const response = await axios.get('/api/Cargo/SetNotification');
            const data = response.data.data;
            return data
        } catch (err) {
            messageBox(err, "error");
        }
    };
    const GetKampanyaList = async () => {
        try {
            const response = await axios.get('/api/Cargo/GetKampanyaList');
            const data = response.data.data;
            return data
        } catch (err) {
            messageBox(err, "error");
        }
    };
    const SetDeviceToken = (Token, ApiVersion, Platform) => {
        try {
            const response = axios.get(`/api/App/SetDeviceToken?Token=${Token}&ApiVersion=${ApiVersion}&Platform=${Platform}`);
            const data = response.data.data;
            return data
        } catch (err) {
            messageBox(err, "error");
        }
    };
    return { SetNotification, GetNotificationList, GetKampanyaList, SetDeviceToken }
}
