import useMessage from '../../features/hooks/useMessage';
import axios from '../../utils/axios';

export default function useGeneralContext() {
  const { messageBox } = useMessage();

  const GetCurrency = async (Kodu) => {
    try {
      const response = await axios.get(`/api/Siparis/GetDovizKuru`);
      if (response.data.data[0]) {
        return parseFloat(response.data.data[0].value).toFixed(4);
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const GetCustomerByName = async (Code) => {
    try {
      const response = await axios.post(`/api/App/GetCustomerByName`, { Code });
      const data = response.data.data[0];
      return data;
    } catch (err) {
      messageBox('Kayıt Bulunamadı', 'error');
    }
  };

  const CustomerInsert = async (data) => {
    try {
      const response = await axios.post(`/api/app/customerInsert`, data);
      if (response.data.data[0].sonuc) {
        messageBox('Başarılı', 'success');
        return response.data.data[0].sonuc;
      } else {
        if (response.data.data[0].message) {
          messageBox(response.data.data[0].message, 'error');
        } else {
          messageBox('Hata Oluştu', 'error');
        }
      }
      // const data = response.data.data[0];
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const GetSupplier = async () => {
    try {
      const response = await axios.get(`/api/Siparis/GetSupplier`);
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const GetCountry = async () => {
    try {
      const response = await axios.get(`/api/App/GetCountry`);
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetCountries = async () => {
    try {
      const response = await axios.get(`/api/App/GetCountries`);
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetCountryFromAlpha2Code = async (AlphaCode) => {
    try {
      const response = await axios.get(
        `/api/App/GetCountryFromAlpha2Code?AlphaCode=${AlphaCode}`
      );
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetCity = async (Kodu) => {
    try {
      const response = await axios.get(`/api/App/GetCity?Kodu=${Kodu}`);
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetCities = async (CountryCode) => {
    try {
      const response = await axios.get(
        `/api/App/GetCities?CountryCode=${CountryCode}`
      );
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err.message || 'Bilinmeyen bir hata oluştu', 'error');
    }
  };
  const SetCustomerCreditLimit = async (Info) => {
    try {
      const response = await axios.post('/api/App/SetCustomerCreditLimit', {
        code: Info.code,
        total: Info.total,
        expiry: Info.expiry,
        currency: Info.currency
      });
      const data = response.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetCustomerByInfoFromMikro = async (Code) => {
    try {
      const response = await axios.get(
        '/api/App/GetCustomerByInfoFromMikro?Code=' + Code
      );
      const data = response.data.data;
      return data[0];
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  return {
    GetCity,
    GetCountry,
    GetCustomerByName,
    CustomerInsert,
    GetSupplier,
    GetCurrency,
    SetCustomerCreditLimit,
    GetCustomerByInfoFromMikro,
    GetCountries,
    GetCountryFromAlpha2Code,
    GetCities
  };
}
