import axios from 'axios';

const axiosInt = axios.create({
  baseURL: import.meta.env.VITE_APP_AUTH0_DOMAIN,
  headers: { 'Content-Type': 'application/json' }
});

axiosInt.interceptors.response.use(
  (response) => {
    // Sunucudan gelen yanıtın başlıklarını kontrol edebilirsiniz
    const coopHeader = response.headers['cross-origin-opener-policy'];
    const coepHeader = response.headers['cross-origin-embedder-policy'];

    if (!coopHeader || !coepHeader) {
      console.warn('Sunucuda COOP veya COEP başlığı eksik.');
    }

    return response;
  },
  (error) =>
    Promise.reject((error.response && error.response.data) || 'BirHataOlustu')
);

export default axiosInt;
