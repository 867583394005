import useMonthNames from "../helpers/useMonthNames";
import useDate from "./useDate";

export default function useMonth() {
    const { monthNames } = useMonthNames()
    const { ilkTarih, sonTarih } = useDate();

    let months;
    let d2 = new Date(sonTarih);
    let d1 = new Date(ilkTarih);

    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    const difference = months <= 0 ? 0 : months;

    const lastMonthIndex = d2.getMonth();
    let firstMonthIndex = d2.getMonth() - difference;
    let labels = [];

    labels = monthNames.filter(( index) => {
        return index <= lastMonthIndex && index >= firstMonthIndex;
    })

    let lastMonths = [];
    let cumulativeMonths = [];
    let year = d2.getFullYear() - 1;

    if (firstMonthIndex < 0) {
        let i = 1;
        while (firstMonthIndex < 0) {
            lastMonths.push(monthNames[firstMonthIndex + 12] + year)
            i++;
            if (i % 12 === 0) {
                cumulativeMonths = lastMonths.concat(labels);
                lastMonths = [];
                year--;
            }
            firstMonthIndex++;
        }
    }
    if (year === d2.getFullYear() - 1) {
        return lastMonths.concat(labels);
    } else {
        return lastMonths.concat(cumulativeMonths);
    }
}
