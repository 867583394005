import { useState, createContext, useEffect } from 'react';
import {
  HavaleHesap,
  HavaleListesi,
  KargoHesap,
  KargoListesi,
  KargoTakip,
  Talimat,
  AracTakip,
  AracTakipGumruk,
  AracTakipYurtDisi,
  AracUrunListesi
} from '../content/Kargo';
import useAuth from '../features/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Branch from '../content/Branch';
import Kampanya from '../content/Kampanya';
import About from '../content/pages/About';
import Fatura from '../content/pages/Fatura';
import MainPage from '../content/MainPage';
import CariHesapLimit from '../content/Finans/CariHesapLimit';
export const MenuContext = createContext({});

export const MenuProvider = ({ children }) => {
  const [currentMenus, setCurrentMenus] = useState([]);
  const [SelectedComponentId, setSelectedComponentId] = useState('');
  const { t } = useTranslation();

  const selectedComponentHandler = (value) => {
    setSelectedComponentId(value);
  };

  const findSelectedComponentByUsingId = (id) => {
    currentMenus.forEach((menu) => {
      if (menu.id === id) {
        setSelectedComponentId(currentMenus.indexOf(menu));
      }
    });
  };

  const { user } = useAuth();

  let CurrentComponent = '';

  const menuItems = {
    Customer: [
      {
        id: 0,
        to: '/kargo/KargoTakip',
        name: t('UrunTakip'),
        component: KargoTakip,
        icon: 'car-sport-outline',
        isShowMenu: true
      },
      {
        id: 1,
        to: '/kargo/KargoListesi',
        name: t('UrunListesi'),
        component: KargoListesi,
        icon: 'airplane-outline',
        isShowMenu: true
      },
      {
        id: 2,
        to: '/kargo/KargoHesap',
        name: t('HesapEkstresi'),
        component: KargoHesap,
        icon: 'briefcase-outline',
        isShowMenu: true
      },
      {
        id: 3,
        to: '/kargo/HavaleHesap',
        name: t('HavaleHesap'),
        component: HavaleHesap,
        icon: 'logo-usd',
        isShowMenu: true
      },
      {
        id: 4,
        to: '/kargo/HavaleListesi',
        name: t('HavaleListesi'),
        component: HavaleListesi,
        icon: 'logo-bitcoin',
        isShowMenu: true
      },
      {
        id: 5,
        to: '/kargo/TalimatListesi',
        name: t('TalimatListesi'),
        component: Talimat,
        icon: 'logo-tumblr',
        isShowMenu: true
      },
      {
        id: 6,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'storefront-outline',
        isShowMenu: true
      },
      {
        id: 7,
        to: '/Kampanya',
        name: t('Kampanya'),
        component: Kampanya,
        icon: 'gift-outline',
        isShowMenu: false
      },
      {
        id: 8,
        to: '/fatura',
        name: t('FaturaBilgileri'),
        component: Fatura,
        icon: 'reader-outline',
        isShowMenu: false
      },
      {
        id: 9,
        to: '/about',
        name: t('Hakkinda'),
        component: About,
        icon: 'information-outline',
        isShowMenu: false
      }
    ],
    Admin: [
      {
        id: 0,
        to: '/kargo/KargoTakip',
        name: t('UrunTakip'),
        component: KargoTakip,
        icon: 'car-sport-outline',
        isShowMenu: true
      },
      {
        id: 1,
        to: '/kargo/KargoListesi',
        name: t('UrunListesi'),
        component: KargoListesi,
        icon: 'airplane-outline',
        isShowMenu: true
      },
      {
        id: 2,
        to: '/kargo/KargoHesap',
        name: t('HesapEkstresi'),
        component: KargoHesap,
        icon: 'briefcase-outline',
        isShowMenu: true
      },
      {
        id: 3,
        to: '/kargo/HavaleHesap',
        name: t('HavaleHesap'),
        component: HavaleHesap,
        icon: 'logo-usd',
        isShowMenu: true
      },
      {
        id: 4,
        to: '/kargo/HavaleListesi',
        name: t('HavaleListesi'),
        component: HavaleListesi,
        icon: 'logo-bitcoin',
        isShowMenu: true
      },
      {
        id: 5,
        to: '/kargo/TalimatListesi',
        name: t('TalimatListesi'),
        component: Talimat,
        icon: 'logo-tumblr',
        isShowMenu: true
      },
      {
        id: 6,
        to: '/kargo/AracTakip',
        name: t('AracTakip'),
        component: AracTakip,
        icon: 'car-sport-outline',
        isShowMenu: true
      },
      {
        id: 7,
        to: '/kargo/AracTakipGumruk',
        name: t('AracTakipGumruk'),
        component: AracTakipGumruk,
        icon: 'car-sport-outline',
        isShowMenu: true
      },
      {
        id: 8,
        to: '/kargo/AracTakipYurtDisi',
        name: t('AracTakipYurtDisi'),
        component: AracTakipYurtDisi,
        icon: 'car-sport-outline',
        isShowMenu: true
      },
      {
        id: 6,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'storefront-outline',
        isShowMenu: true
      },
      {
        id: 9,
        to: '/Kampanya',
        name: t('Kampanya'),
        component: Kampanya,
        icon: 'gift-outline',
        isShowMenu: false
      },
      {
        id: 10,
        to: '/fatura',
        name: t('FaturaBilgileri'),
        component: Fatura,
        icon: 'reader-outline',
        isShowMenu: false
      },
      {
        id: 11,
        to: '/about',
        name: t('Hakkinda'),
        component: About,
        icon: 'information-outline',
        isShowMenu: false
      }
    ],
    Unknown: [
      {
        id: 0,
        to: '/',
        name: t('AnaSayfa'),
        component: MainPage,
        icon: 'earth-outline',
        isShowMenu: true
      },
      {
        id: 0,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'storefront-outline',
        isShowMenu: true
      },
      {
        id: 1,
        to: '/Kampanya',
        name: t('Kampanya'),
        component: Kampanya,
        icon: 'gift-outline',
        isShowMenu: true
      },
      {
        id: 2,
        to: '/fatura',
        name: t('FaturaBilgileri'),
        component: Fatura,
        icon: 'reader-outline',
        isShowMenu: true
      },
      {
        id: 3,
        to: '/about',
        name: t('Hakkinda'),
        component: About,
        icon: 'information-outline',
        isShowMenu: true
      }
    ],
    Saler: [
      {
        id: 0,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'storefront-outline',
        isShowMenu: true
      },
      {
        id: 1,
        to: '/Kampanya',
        name: t('Kampanya'),
        component: Kampanya,
        icon: 'gift-outline',
        isShowMenu: true
      },
      {
        id: 2,
        to: '/fatura',
        name: t('FaturaBilgileri'),
        component: Fatura,
        icon: 'reader-outline',
        isShowMenu: false
      },
      {
        id: 3,
        to: '/about',
        name: t('Hakkinda'),
        component: About,
        icon: 'information-outline',
        isShowMenu: false
      }
    ],
    SuperAdmin: [
      {
        id: 0,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'storefront-outline',
        isShowMenu: true
      },
      {
        id: 1,
        to: '/Kampanya',
        name: t('Kampanya'),
        component: Kampanya,
        icon: 'gift-outline',
        isShowMenu: true
      },
      {
        id: 2,
        to: '/fatura',
        name: t('FaturaBilgileri'),
        component: Fatura,
        icon: 'reader-outline',
        isShowMenu: false
      },
      {
        id: 3,
        to: '/about',
        name: t('Hakkinda'),
        component: About,
        icon: 'information-outline',
        isShowMenu: false
      }
    ],
    Manager: [
      {
        id: 0,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'storefront-outline',
        isShowMenu: true
      },
      {
        id: 1,
        to: '/Kampanya',
        name: t('Kampanya'),
        component: Kampanya,
        icon: 'gift-outline',
        isShowMenu: true
      },
      {
        id: 2,
        to: '/fatura',
        name: t('FaturaBilgileri'),
        component: Fatura,
        icon: 'reader-outline',
        isShowMenu: false
      },
      {
        id: 3,
        to: '/about',
        name: t('Hakkinda'),
        component: About,
        icon: 'information-outline',
        isShowMenu: false
      }
    ],
    Employee1: [
      {
        id: 0,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'storefront-outline',
        isShowMenu: true
      },
      {
        id: 1,
        to: '/Kampanya',
        name: t('Kampanya'),
        component: Kampanya,
        icon: 'gift-outline',
        isShowMenu: true
      },
      {
        id: 2,
        to: '/fatura',
        name: t('FaturaBilgileri'),
        component: Fatura,
        icon: 'reader-outline',
        isShowMenu: false
      },
      {
        id: 3,
        to: '/about',
        name: t('Hakkinda'),
        component: About,
        icon: 'information-outline',
        isShowMenu: false
      }
    ],
    Employee2: [
      {
        id: 0,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'storefront-outline',
        isShowMenu: true
      },
      {
        id: 1,
        to: '/Kampanya',
        name: t('Kampanya'),
        component: Kampanya,
        icon: 'gift-outline',
        isShowMenu: true
      },
      {
        id: 2,
        to: '/fatura',
        name: t('FaturaBilgileri'),
        component: Fatura,
        icon: 'reader-outline',
        isShowMenu: false
      },
      {
        id: 3,
        to: '/about',
        name: t('Hakkinda'),
        component: About,
        icon: 'information-outline',
        isShowMenu: false
      }
    ],
    Employee3: [
      {
        id: 0,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'storefront-outline',
        isShowMenu: true
      },
      {
        id: 1,
        to: '/Kampanya',
        name: t('Kampanya'),
        component: Kampanya,
        icon: 'gift-outline',
        isShowMenu: true
      },
      {
        id: 2,
        to: '/fatura',
        name: t('FaturaBilgileri'),
        component: Fatura,
        icon: 'reader-outline',
        isShowMenu: false
      },
      {
        id: 3,
        to: '/about',
        name: t('Hakkinda'),
        component: About,
        icon: 'information-outline',
        isShowMenu: false
      }
    ],
    Follower1: [
      {
        id: 0,
        to: '/kargo/AracTakipGumruk',
        name: t('AracTakipGumruk'),
        component: AracTakipGumruk,
        icon: 'car-sport-outline',
        isShowMenu: true
      },
      {
        id: 1,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'storefront-outline',
        isShowMenu: true
      },
      {
        id: 2,
        to: '/Kampanya',
        name: t('Kampanya'),
        component: Kampanya,
        icon: 'gift-outline',
        isShowMenu: true
      },
      {
        id: 3,
        to: '/fatura',
        name: t('FaturaBilgileri'),
        component: Fatura,
        icon: 'reader-outline',
        isShowMenu: false
      },
      {
        id: 4,
        to: '/about',
        name: t('Hakkinda'),
        component: About,
        icon: 'information-outline',
        isShowMenu: false
      }
    ],
    Follower2: [
      {
        id: 0,
        to: '/kargo/AracTakip',
        name: t('AracTakip'),
        component: AracTakip,
        icon: 'car-sport-outline',
        isShowMenu: true
      },
      {
        id: 1,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'storefront-outline',
        isShowMenu: true
      },
      {
        id: 2,
        to: '/Kampanya',
        name: t('Kampanya'),
        component: Kampanya,
        icon: 'gift-outline',
        isShowMenu: true
      },
      {
        id: 3,
        to: '/fatura',
        name: t('FaturaBilgileri'),
        component: Fatura,
        icon: 'reader-outline',
        isShowMenu: false
      },
      {
        id: 4,
        to: '/about',
        name: t('Hakkinda'),
        component: About,
        icon: 'information-outline',
        isShowMenu: false
      }
    ],
    Follower3: [
      {
        id: 0,
        to: '/kargo/AracTakipYurtDisi',
        name: t('AracTakipYurtDisi'),
        component: AracTakipGumruk,
        icon: 'car-sport-outline',
        isShowMenu: true
      },
      {
        id: 1,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'storefront-outline',
        isShowMenu: true
      },
      {
        id: 2,
        to: '/Kampanya',
        name: t('Kampanya'),
        component: Kampanya,
        icon: 'gift-outline',
        isShowMenu: true
      },
      {
        id: 3,
        to: '/fatura',
        name: t('FaturaBilgileri'),
        component: Fatura,
        icon: 'reader-outline',
        isShowMenu: false
      },
      {
        id: 4,
        to: '/about',
        name: t('Hakkinda'),
        component: About,
        icon: 'information-outline',
        isShowMenu: false
      }
    ],
   
    FinanceManager: [
      {
        id: 1,
        to: '/finans/CariHesapLimit',
        name: t('Cari Hesap Limit'),
        component: CariHesapLimit,
        icon: 'information-outline',
        isShowMenu: true
      },
      {
        id: 2,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'storefront-outline',
        isShowMenu: true
      },
      {
        id: 3,
        to: '/Kampanya',
        name: t('Kampanya'),
        component: Kampanya,
        icon: 'gift-outline',
        isShowMenu: true
      },
      {
        id: 4,
        to: '/fatura',
        name: t('FaturaBilgileri'),
        component: Fatura,
        icon: 'reader-outline',
        isShowMenu: false
      },
      {
        id: 5,
        to: '/about',
        name: t('Hakkinda'),
        component: About,
        icon: 'information-outline',
        isShowMenu: false
      }
    ],
    ShippingManager: [
      {
        id: 1,
        to: '/kargo/AracUrunListesi',
        name: t('AracUrunListesi'),
        component: AracUrunListesi,
        icon: 'dice-outline',
        isShowMenu: true
      },
      {
        id: 2,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'storefront-outline',
        isShowMenu: true
      },
      {
        id: 3,
        to: '/finans/CariHesapLimit',
        name: t('Cari Hesap Limit'),
        component: CariHesapLimit,
        icon: 'information-outline',
        isShowMenu: true
      }
    ]
  };

  useEffect(() => {
    if (user) {
      const { authenticationProviderType } = user;
      setCurrentMenus(menuItems[authenticationProviderType]);
    }
  }, [user]);

  if (SelectedComponentId) {
    CurrentComponent = currentMenus[SelectedComponentId]['component'];
  } else {
    CurrentComponent = '';
  }

  return (
    <MenuContext.Provider
      value={{
        currentMenus,
        SelectedComponentId,
        CurrentComponent,
        selectedComponentHandler,
        findSelectedComponentByUsingId
      }}>
      {children}
    </MenuContext.Provider>
  );
};
