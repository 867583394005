import { createContext, useEffect, useReducer, useState } from 'react';
import axios from '../utils/axios';
import { verify, JWT_SECRET } from '../utils/jwt';
import PropTypes from 'prop-types';
import useMessage from '../features/hooks/useMessage';
import internationalization from '../assets/i18n/i18n';
import {
  signInWithGoogle,
  signInWithApple,
  authenticateWithBackend
} from '../services/auth/firebaseAuth'; // Firebase Auth fonksiyonları

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  success: '',
  message: ''
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  firebaseLogin: () => Promise.resolve(),
  SetUserPassword: () => Promise.resolve(),
  GetUserTemporaryPassword: () => Promise.resolve(),
  SetUserForgetPassword: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const { messageBox } = useMessage();
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const [notifications, setNotifications] = useState([]);

  const getUnreadNotifications = async () => {
    if (state.user) {
      const response = await axios.get('/api/Cargo/GetNotificationList');
      if (response.data.success) {
        setNotifications(response.data.data);
      }
    }
  };

  const GetUserByName = async () => {
    try {
      const response = await axios.get('/api/App/GetUserByName');
      const data = response.data.data[0];

      return data;
    } catch (err) {
      const errorMessage =
        err.response?.data?.message ||
        'Kullanıcı bilgisi alınırken bir hata oluştu.';
      messageBox(errorMessage, 'error');
      return null; // Hata durumunda null döndürmek daha güvenli olabilir
    }
  };

  useEffect(() => {
    if (state.user) {
      internationalization.changeLanguage(state.user.language);
      setInterval(() => {
        getUnreadNotifications();
      }, 300000);
      getUnreadNotifications();
    }
  }, [state.user]);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && verify(accessToken, JWT_SECRET)) {
          setSession(accessToken);

          const response = await axios.get('/api/App/GetUserByName');
          const user = response.data.data[0];
          console.log(user);
          internationalization.changeLanguage(user.language);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  // JWT ile klasik giriş işlemi
  const login = async (UserName, Password) => {
    try {
      const response = await axios.post('/api/Auth/login', {
        UserName,
        Password
      });

      const { token } = response.data.data;
      setSession(token);

      const user = await GetUserByName();

      internationalization.changeLanguage(user.language);

      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  // Çıkış işlemi
  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };
  // Klasik kayıt işlemi
  const register = async (userInfo) => {
    const response = await axios.post('/api/Auth/register', {
      Email: userInfo.Email,
      UserName: userInfo.UserName,
      Password: userInfo.Password,
      FullName: userInfo.FullName,
      MobilePhones: userInfo.MobilePhones
    });

    if (response.data.success) {
      await login(userInfo.UserName, userInfo.Password);
    } else {
      messageBox('Password Error', 'error');
    }
  };
  // Kullanıcı dilini değiştirme
  const SetUserLanguage = async (Lang) => {
    try {
      const response = await axios.get('/api/App/SetUserLanguage?Lang=' + Lang);
      const data = response.data.data[0];

      dispatch({
        type: 'REGISTER',
        payload: {
          type: 'LOGIN',
          user: data
        }
      });
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetUserTemporaryPassword = async (userName, password) => {
    try {
      const response = await axios.get(
        `/api/App/GetUserTemporaryPassword?userName=${userName}&password=${password}`
      );

      if (response.data.data[0].sonuc) {
        messageBox('Success', 'success');
        await login(userName, password);
      } else {
        messageBox('Password Error', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const SetUserPassword = async (oldPassword, newPassword) => {
    try {
      await axios.get(
        `/api/App/SetUserPasswordChange?oldPassword=${oldPassword}&newPassword=${newPassword}`
      );
      messageBox('Success', 'success');

      const responseUser = await axios.get('/api/App/GetUserByName');

      const user = responseUser.data.data[0];

      internationalization.changeLanguage(user.language);

      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const SetUserForgetPassword = async (userName, phone) => {
    try {
      const response = await axios.get(
        `/api/App/SetUserForgetPassword?userName=${userName}&phone=${phone}`
      );
      return response;
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  // Firebase ile giriş işlemi
  const firebaseLogin = async (provider) => {
    try {
      let firebaseToken;
      // Firebase sağlayıcıya göre giriş
      if (provider === 'google') {
        firebaseToken = await signInWithGoogle();
      } else if (provider === 'apple') {
        firebaseToken = await signInWithApple();
      }
      // Firebase token'ı backend'e gönder
      const jwttoken = await authenticateWithBackend(firebaseToken);
      setSession(jwttoken);
      console.log('jwttoken: ', jwttoken);
      const user = await GetUserByName();
      console.log('user: ', user);

      internationalization.changeLanguage(user.language);

      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        firebaseLogin, // Firebase login'i de ekledik
        logout,
        register,
        setNotifications,
        SetUserLanguage,
        SetUserPassword,
        GetUserTemporaryPassword,
        SetUserForgetPassword,
        notifications
      }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
