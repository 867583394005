import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import useAuth from '../../../features/hooks/useAuth';
import useMenu from '../../../features/hooks/useMenu';
import './bottommenu.css'
import { RiMenuFill } from "react-icons/ri";
import { RxReader } from "react-icons/rx";
import { ImGift } from "react-icons/im";
import { FaCodeBranch } from "react-icons/fa6";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";

export default function BottomMenu() {
    const { t } = useTranslation();
    const { selectedComponentHandler } = useMenu();
    const [currentMenus, setCurrentMenus] = useState("Unknown");
    const { user } = useAuth();

    useEffect(() => {
        if (user) {
            const { authenticationProviderType } = user;
            setCurrentMenus(authenticationProviderType)
        }
    }, [user]);

    if (currentMenus == "Customer") {
        return (
            <nav className="navbar fixed-bottom navbar-light bg-white bg-light m-0 shadow-sm d-md-none py-2 bottom-menu">
                <div className="container-fluid d-block p-0 mx-2">
                    <div className='row'>
                        <NavLink to={"/"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                            {/* <ion-icon name="cash-outline"></ion-icon> */}
                            <FaMoneyCheckDollar size={24} />
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Hesap')}</button>
                        </NavLink>
                        <NavLink to={"/kargo/KargoTakip"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                            {/* <ion-icon name="car-sport-outline"></ion-icon> */}
                            <MdOutlineProductionQuantityLimits size={24} />
                            <button className="text-secondary btn m-0 p-0" href="#">{t('TakipEt')}</button>
                        </NavLink>
                        <NavLink to={"/menu"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                        <RiMenuFill size={24} />
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Menu')}</button>
                        </NavLink>
                        <NavLink to={"/Kampanya"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                        <ImGift size={24}/>
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Kampanya')}</button>
                        </NavLink>
                    </div>
                </div>
            </nav>
        )
    } else if (currentMenus == "Follower2") {
        return (
            <nav className="navbar fixed-bottom navbar-light bg-white bg-light m-0 shadow-sm d-md-none py-2 bottom-menu">
                <div className="container-fluid d-block p-0 mx-2">
                    <div className='row'>
                        <NavLink to={"/"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                            <ion-icon name="car-sport-outline"></ion-icon>
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Takip')}</button>
                        </NavLink>
                        <NavLink to={"/dashboard/AracTakip"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                            <ion-icon name="cash-outline"></ion-icon>
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Pano')}</button>
                        </NavLink>
                        <NavLink to={"/menu"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                        <RiMenuFill size={24} />
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Menu')}</button>
                        </NavLink>
                        <NavLink to={"/branch"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                        <FaCodeBranch size={24} />
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Sube')}</button>
                        </NavLink>
                    </div>
                </div>
            </nav>
        )
    }
    else {
        return (
            <nav className="navbar fixed-bottom navbar-light bg-white bg-light m-0 shadow-sm d-md-none py-2 bottom-menu">
                <div className="container-fluid d-block p-0 mx-2">
                    <div className='row'>
                        <NavLink to={"/"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                        <RiMenuFill size={24} />
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Menu')}</button>
                        </NavLink>
                        <NavLink to={"/fatura"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                            <RxReader size={24} />
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Fatura')}</button>
                        </NavLink>
                        <NavLink to={"/branch"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                            <FaCodeBranch size={24} />
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Sube')}</button>
                        </NavLink>
                        <NavLink to={"/Kampanya"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                            <ImGift size={24}/>
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Kampanya')}</button>
                        </NavLink>
                    </div>
                </div>
            </nav>
        )
    }

}
