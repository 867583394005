import { useState } from 'react';
import { CiLogin } from 'react-icons/ci';
import { useTranslation } from 'react-i18next';

const AcountLoginForm = ({ onSubmit, requestPending }) => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();

  const isSubmit = userName.trim() && password.trim();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(userName, password); // login işlemi üst bileşende yapılacak
  };

  return (
    <form onSubmit={handleSubmit} className="d-grid">
      <div className="mb-3">
        <div className="input-group">
          <div className="bg-color-layout-shadow d-flex align-items-center p-1 px-2">
            <span className="black-color">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22}
                height={22}
                viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>
          <input
            className="formControl-text form-control"
            id="username"
            type="text"
            placeholder={t('UserCode')}
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
      </div>
      <div className="mb-3">
        <div className="input-group">
          <div className="bg-color-layout-shadow d-flex align-items-center p-1 px-2">
            <span className="black-color">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22}
                height={22}
                viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>
          <input
            className="formControl-text form-control"
            id="password"
            type="password"
            placeholder={t('Sifre')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </div>
      <div className="btn-container">
        <button
          className="btn-login"
          type="submit"
          disabled={!isSubmit || requestPending}>
          <CiLogin size={28} fontWeight={600} color="white" />
          <p className="m-0">{t('Giris')}</p>
        </button>
      </div>
    </form>
  );
};

export default AcountLoginForm;
