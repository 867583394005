import Layout from '../../Layouts';
import { useTranslation } from 'react-i18next';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import useCargoApiContext from '../../../context/api/useCargoApiContext';
import useDate from '../../../features/hooks/useDate';
import useMonthNames from '../../../features/helpers/useMonthNames';
import useMonth from '../../../features/hooks/useMonth';
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom';
import formatCurrency from '../../../utils/format-currency';
import useAuth from '../../../features/hooks/useAuth';
import TabNavigation from '../../../components/TabNavigation';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: 'Tarih - M3'
    }
  }
};

function CargoDashboard() {
  const { t } = useTranslation();
  const { GetCargoGonderiList, GetAnaCariBakiyeList, GetCargoBakiye } =
    useCargoApiContext();
  const [bakiyeList, setBakiyeList] = useState([]);
  const [kargoBakiye, setKargoBakiye] = useState(0);
  const [subAccount, setSubAccount] = useState(0);
  const [data, setData] = useState('');
  const { ilkTarih, sonTarih } = useDate();
  const { monthNames } = useMonthNames();
  const { user } = useAuth();
  const labels = useMonth();
  let navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      const result = await GetCargoGonderiList(ilkTarih, sonTarih, subAccount);
      setData(result);
      const bakiyeList = await GetAnaCariBakiyeList(subAccount);
      setBakiyeList(bakiyeList);
      const kargoBakiyeResponse = await GetCargoBakiye(subAccount);
      setKargoBakiye(
        kargoBakiyeResponse?.[0]?.bakiye ? kargoBakiyeResponse[0].bakiye : 0
      );
    }
    if (ilkTarih && sonTarih && user) {
      getData();
    }
    return () => {
      setData('');
    };
  }, [ilkTarih, sonTarih, user, subAccount]);

  let toplamKap = 0;
  let toplamKg = 0;
  let toplamM3 = 0;
  const kapsayisi = [];
  const m3 = [];

  if (data) {
    data.forEach((el) => {
      const elDate = new Date(el.tarih);
      toplamKap += parseInt(el.kapSayisi);
      toplamKg += parseFloat(el.kg);
      toplamM3 += parseFloat(el.m3);
      let currentLabel = '';
      if (elDate.getFullYear() === new Date().getFullYear()) {
        currentLabel = monthNames[elDate.getMonth()];
      } else {
        currentLabel = monthNames[elDate.getMonth()] + elDate.getFullYear();
      }
      labels.forEach((label) => {
        if (label == currentLabel) {
          if (typeof kapsayisi[labels.indexOf(label)] === 'undefined') {
            kapsayisi[labels.indexOf(label)] = 0;
            kapsayisi[labels.indexOf(label)] =
              kapsayisi[labels.indexOf(label)] + parseInt(el.kapSayisi);
          } else {
            kapsayisi[labels.indexOf(label)] =
              kapsayisi[labels.indexOf(label)] + parseInt(el.kapSayisi);
          }
          if (typeof m3[labels.indexOf(label)] === 'undefined') {
            m3[labels.indexOf(label)] = 0;
            m3[labels.indexOf(label)] =
              m3[labels.indexOf(label)] + parseInt(el.m3);
          } else {
            m3[labels.indexOf(label)] =
              m3[labels.indexOf(label)] + parseInt(el.m3);
          }
        }
      });
    });
  }

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Kapsayısı',
        data: kapsayisi,
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      },
      {
        label: 'M3',
        data: m3,
        backgroundColor: 'rgba(53, 162, 235, 0.5)'
      }
    ]
  };

  return (
    <Layout>
      {user?.subAccount == 1 && (
        <TabNavigation activeTab={subAccount} setActiveTab={(value) => setSubAccount(value)} />
      )}
      <div className="container-fluid px-0 mb-4">
        <div className="row gx-0 gap-3">
          <div className="bg-white rounded-3 text-secondary position-relative col-lg-4 gl-card-decorative">
            <div className="border-bottom-1 pt-2 bg-color-layout-shadow">
              <h4 className="ps-3 fw-normal">{t('HavaleBakiye')}</h4>
            </div>
            {bakiyeList &&
              bakiyeList.length > 0 &&
              bakiyeList.map((el, i) => {
                return (
                  <div
                    key={i}
                    className="mt-2 border-bottom-1 px-3 py-1 d-flex align-items-center justify-content-between">
                    <div>
                      <h6 className="text-secondary mb-0">{t(el.hesapAdi)}</h6>
                      {/* <h6 className="text-secondary mb-0">{`${el.hesapAdi}`}</h6> */}
                      <h4 className="mt-2 text-color-decorative">
                        {formatCurrency(el?.bakiye)} {el.doviz}
                      </h4>
                    </div>
                    <div>
                      <button
                        className="btn bg-color-decorative text-white"
                        onClick={() =>
                          navigate(`/kargo/HavaleHesap/${el.kodu}`)
                        }>
                        {t('Detay')}
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="bg-white rounded-3 text-secondary position-relative col-lg-4 gl-card-decorative">
            <div className="border-bottom-1 pt-2 bg-color-layout-shadow">
              <h4 className="ps-3 fw-normal">{t('DepoBakiye')}</h4>
            </div>
            <div className="mt-2 border-bottom-1 px-3 py-1 d-flex align-items-center justify-content-between">
              <div>
                <h6 className="text-secondary mb-0">USD</h6>
                <h4 className="mt-2 text-color-decorative">
                  {formatCurrency(kargoBakiye)}
                </h4>
              </div>
              <div>
                <button
                  className="btn bg-color-decorative text-white"
                  onClick={() => navigate(`/kargo/KargoHesap`)}>
                  {t('Detay')}
                </button>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-3 p-4 d-flex align-items-center justify-content-between text-secondary col-lg-4 gl-card-decorative">
            <div className="d-flex flex-column align-items-center">
              <div className="bg-color-decorative text-white rounded-circle p-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={30}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div>
                <p className="text-secondary mb-0 text-center mt-1">
                  {t('PaketSayisi')}
                </p>
                <CountUp
                  start={0}
                  end={toplamKap.toFixed(0)}
                  duration={2}
                  decimals={0}
                  decimal=",">
                  {({ countUpRef }) => (
                    <h3
                      ref={countUpRef}
                      className="mt-1 text-color-decorative text-center"></h3>
                  )}
                </CountUp>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="bg-color-decorative text-white rounded-circle p-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={30}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <div>
                <p className="text-secondary mb-0 text-center mt-1">
                  {t('Kg')}
                </p>
                <CountUp
                  start={0}
                  end={toplamKg}
                  duration={2}
                  decimals={0}
                  decimal=",">
                  {({ countUpRef }) => (
                    <h3
                      ref={countUpRef}
                      className="mt-1 text-color-decorative text-center"></h3>
                  )}
                </CountUp>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="bg-color-decorative text-white rounded-circle p-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={30}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
                  />
                </svg>
              </div>
              <div>
                <p className="text-secondary mb-0 text-center mt-1">
                  {t('M3')}
                </p>
                <CountUp
                  start={0}
                  end={toplamM3.toFixed(2)}
                  duration={2}
                  decimals={2}
                  decimal=",">
                  {({ countUpRef }) => (
                    <h3
                      ref={countUpRef}
                      className="mt-1 text-color-decorative text-center"></h3>
                  )}
                </CountUp>
              </div>
            </div>
          </div>
          <div className="gl-card-decorative col-lg-4 rounded-3">
            <div className="card-header bg-color-decorative text-white text-center fw-bold">
              {t('M3Kg')}
            </div>
            <div className="card-body">
              {data && <Bar options={options} data={chartData} />}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CargoDashboard;
