import { useTranslation } from "react-i18next";
import Layout from "../../Layouts";

function About() {
    const { t } = useTranslation();

    return (
        <Layout>
            <h3>{t('Hakkimizda')}</h3>
            <div>
                <p>Altuntur Nak. İnş. İç ve Dış. Ltd. Şti</p>
                Türkiye - Irak merkezli hizmet vermek amacıyla 1997 yılında kurulan ve sektörün en hızlı büyüyen şirketlerinden biridir.
                24 yılı aşkın deneyimleri güven ve üstün hizmet anlayışı ile
                Irak’ın
                <p>
                    <li>Bağdat</li>
                    <li>Basra</li>
                    <li>Erbil</li>
                    <li>Süleymaniye</li>
                    <li>Kerkük</li>
                    <li>Duhok</li>
                    <li>Zaho</li>
                    <li>Kerbela</li>
                    <li>Hille</li>
                    <li>Necef</li>
                    <li>Samara</li>
                    <li>Tikrit</li>
                    <li>Akre</li>
                    <li>Divaniye</li>
                    <li>Diyana</li>
                    <li>diğer bölgelerine.. </li>
                </p>

                <p>Hizmet vermektedir.</p>
                <p>
                    Altuntur Nak. İnş. İç ve Dış. Ltd. Şti.,
                    Uluslararası nakliyede başta Avrupa ve Irak olmak üzere tüm dünyaya;
                    <li>Karayolu</li>
                    <li>Havayolu</li>
                    <li>Denizyolu</li>
                </p>

                Nakliye hizmetleri ile bugün kıtaları birbirine bağlayan bir transit taşımacılık ağı hizmeti sunmaktadır.
            </div>
        </Layout>
    );
}

export default About;