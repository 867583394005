import { useState, useEffect } from 'react';
import './styles.css';
import resim from '@/assets/images/login.png';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FcGoogle } from 'react-icons/fc';
import { FaApple } from 'react-icons/fa';
import { IoMdPhonePortrait } from 'react-icons/io';
import { ImUsers } from 'react-icons/im';
import AcountLoginForm from './AcountLoginForm';
import PhoneLoginForm from './PhoneLoginForm';
import useAuth from '@/features/hooks/useAuth';
import useNotificationApiContext from '@/context/api/useNotificationApiContext';

function Login() {
  const { login, isAuthenticated, firebaseLogin } = useAuth();
  const { SetDeviceToken } = useNotificationApiContext();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const ApiVersion = searchParams.get('ApiVersion');
  const Platform = searchParams.get('Platform');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [stage, setStage] = useState('account'); // Başlangıç olarak hesap login
  const [requestPending, setRequestPending] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const isIOSDevice = () => {
      const platform = window.navigator.platform;
      const userAgent = window.navigator.userAgent;

      const iOSPlatforms = ['iPhone', 'iPad', 'iPod'];
      if (iOSPlatforms.includes(platform)) {
        return true;
      }
      if (/Macintosh/.test(userAgent) && 'ontouchend' in document) {
        return true;
      }
      return false;
    };

    setIsIOS(isIOSDevice());
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(`/`);
    }
  }, [isAuthenticated, navigate]);

  const handleGoogleLogin = async () => {
    try {
      await firebaseLogin('google');
      if (token && ApiVersion && Platform) {
        SetDeviceToken(token, ApiVersion, Platform);
      }
    } catch (error) {
      console.error('Google login failed', error);
    }
  };

  const handleAppleLogin = async () => {
    try {
      await firebaseLogin('apple');
    } catch (error) {
      console.error('Apple login failed', error);
    }
  };

  const handlePhoneLoginClick = () => {
    setStage('phone'); // Telefon login aşamasına geç
  };

  const handleAccountLoginClick = () => {
    setStage('account'); // Hesap login aşamasına geç
  };

  const handleAccountSubmit = async (userName, password) => {
    setRequestPending(true);
    try {
      await login(userName, password);
      if (token && ApiVersion && Platform) {
        SetDeviceToken(token, ApiVersion, Platform);
      }
    } catch (error) {
      console.error('Login error: ', error);
    } finally {
      setRequestPending(false);
    }
  };

  return (
    <div className="container px-4 px-lg-0 login-container">
      <div className="row d-grid d-flex bg-sm-image">
        <div className="col-lg-6 col-md-4 col-sm-10">
          <img src={resim} className="img-login" alt="Login" />
        </div>
        <div className="m-auto rounded-lg form-login g-0 col-lg-6 col-md-8 col-sm-10">
          <div className="card g-0" border="gray">
            <div className="card-header card-header-title text-color-success">
              <img
                src="/images/icons/logo.png"
                className="img-fluid"
                width={60}
                height={60}
                alt="Logo"
              />
              <div>{t('Giris')}</div>
            </div>

            <div className="card-body">
              {stage === 'account' && (
                <AcountLoginForm
                  onSubmit={handleAccountSubmit}
                  requestPending={requestPending}
                />
              )}

              {stage === 'phone' && (
                <PhoneLoginForm
                  onSubmit={handleAccountSubmit}
                  requestPending={requestPending}
                />
              )}

              <div className="icon-container">
                {/* Google Login */}
                <FcGoogle size={36} onClick={handleGoogleLogin} />
                {/* Apple Login */}
                {isIOS && <FaApple size={36} onClick={handleAppleLogin} />}

                {/* Yalnızca form aktif değilse buton göster */}
                {stage === 'account' ? (
                  <IoMdPhonePortrait
                    size={36}
                    onClick={handlePhoneLoginClick}
                  />
                ) : (
                  <ImUsers size={36} onClick={handleAccountLoginClick} />
                )}
              </div>

              <div className="login-footer">
                <p className="forgot-password text-start">
                  <Link to="/auth/ForgetPassword">{t('SifremiUnuttum')}</Link>
                </p>
                <p className="forgot-password text-end">
                  <Link to="/auth/register">{t('KayitOl')}</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
