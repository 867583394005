import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGeneralContext from '../../../context/api/useGeneralContext';
import useMessage from '../../../features/hooks/useMessage';
import Layout from '../../Layouts';
import './CariHesapLimit.css';

function CariHesapLimit() {
  const { t } = useTranslation();
  let MyDate = new Date();
  let date =
    MyDate.getFullYear() +
    '-' +
    ('0' + (MyDate.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + MyDate.getDate()).slice(-2);
  const [vade, setVade] = useState(date);
  const { GetCustomerByInfoFromMikro, SetCustomerCreditLimit } =
    useGeneralContext();
  const [total, setTotal] = useState(0);
  const [currency, setCurrency] = useState(1);
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const { messageBox } = useMessage();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = total > 0 || total != '';
    //select mecburi seşilme kontrolü yap
    if (isValid) {
      const result = await SetCustomerCreditLimit({
        code: code,
        total: total,
        expiry: vade,
        currency: currency
      });
      if (result) {
        if (result.data[0].sonuc == 1) {
          messageBox('İşlem tamamlandı', 'success');
        } else {
          messageBox('İşlem başarısız', 'error');
        }
      }
    } else {
      messageBox('Tutar 0 dan büyük olmalı', 'error');
    }
  };

  const searchCustomer = async (e = null) => {
    if (e) {
      e.preventDefault();
    }
    if (code) {
      const customer = await GetCustomerByInfoFromMikro(code);
      if (customer) {
        setName(customer.unvani);
        setCode(customer.kodu);
        setVade(date);
        setTotal(0);
        setCurrency(customer.dovizCinsi);
      } else {
        messageBox('hesap bulunamadı', 'error');
      }
    } else {
      messageBox('kod yazınız..', 'error');
    }
  };

  return (
    <Layout>
      <div className="container my-4">
        <div className="py-2 px-2 bg-color-primary bg-gradient rounded m-2 gl-page-header-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="nav--hamburger"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
          <p className="text-center m-1 fs-5">{t('Cari Hesap Limit')}</p>
        </div>

        <div className="order-wrapper m-0 ">
          <div className="input-group floating-label-group position-relative costumer-z-5">
            <input
              type="text"
              id="code"
              value={code}
              className="form-control py-2"
              required
              onChange={(e) => setCode(e.target.value)}
            />
            <label className="floating-label">{t('Code')}</label>
            <button
              className="btn btn-outline-primary"
              type="button"
              onClick={() => searchCustomer()}
              id="button-addon1">
              {t('Search')}
            </button>
          </div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="input-group floating-label-group">
              <input
                type="text"
                name="name"
                className="form-control py-2"
                id="name"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
              <label className="floating-label">{t('Name')}</label>
            </div>
            <div className="input-group floating-label-group">
              <input
                type="date"
                className="me-2 date-picker py-2 form-control"
                value={vade}
                onChange={(e) => {
                  setVade(e.target.value);
                }}></input>
              <label className="floating-label">{t('Vade')}</label>
            </div>
            <div className="input-group floating-label-group col">
              <input
                type="number"
                name="total"
                className="form-control py-2"
                id="total"
                value={total}
                required
                onChange={(e) => setTotal(e.target.value)}
              />
              <label className="floating-label">{t('Total')}</label>
            </div>
            <div className="input-group floating-label-group col">
              <select
                className="form-select form-select-lg"
                onChange={(e) => setCurrency(e.target.value)}
                name="currency"
                required
                value={currency}>
                <option value={0} key={0}>
                  TL
                </option>
                <option value={1} key={1}>
                  USD
                </option>
                <option value={2} key={2}>
                  EURO
                </option>
              </select>
              <label className="floating-label">{t('Doviz')}</label>
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-block"
              onClick={(e) => handleSubmit(e)}>
              {t('Kaydet')}
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default CariHesapLimit;
