import { Route, Routes } from 'react-router-dom';
import Auth from './auth';
import RequireAuth from './RequireAuth';
import { lazy, Suspense } from 'react';
import WaitingSpinner from '../components/WaitingSpinner';
import Finans from './finans';
import Kargo from './kargo';
import CargoDashboard from '../content/Dashboard/Kargo';
import useAuth from '../features/hooks/useAuth';
import { AracTakip } from '../content/Kargo';

const Kampanya = lazy(() => import('../content/Kampanya'));
const MainPage = lazy(() => import('../content/MainPage'));
const Branch = lazy(() => import('../content/Branch'));
const Profile = lazy(() => import('../content/Profile'));
const Dashboard = lazy(() => import('./dashboard'));
const OneriSikayet = lazy(() => import('../content/pages/OneriSikayet'));
const About = lazy(() => import('../content/pages/About'));
const Fatura = lazy(() => import('../content/pages/Fatura'));
const Iletisim = lazy(() => import('../content/pages/iletisim'));

const Router = () => {
  const { user } = useAuth();

  const getHomeRoute = () => {
    if (user) {
      const { authenticationProviderType } = user;
      if (
        authenticationProviderType === 'Follower2' ||
        authenticationProviderType === 'Follower3'
      ) {
        return <AracTakip />;
      }
    }
    return <CargoDashboard />;
  };

  return (
    <Suspense fallback={<WaitingSpinner />}>
      <Routes>
        {/* Auth Rotaları */}
        <Route path="/auth/*" element={<Auth />} />

        {/* Ana Sayfa ve Diğer Rotalar */}
        <Route path="/" element={<RequireAuth>{getHomeRoute()}</RequireAuth>} />
        <Route
          path="/menu"
          element={
            <RequireAuth>
              <MainPage />
            </RequireAuth>
          }
        />
        <Route
          path="/kargo/*"
          element={
            <RequireAuth>
              <Kargo />
            </RequireAuth>
          }
        />
        <Route
          path="/profile"
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />
        <Route
          path="/kampanya"
          element={
            <RequireAuth>
              <Kampanya />
            </RequireAuth>
          }
        />
        <Route
          path="/OneriSikayet"
          element={
            <RequireAuth>
              <OneriSikayet />
            </RequireAuth>
          }
        />
        <Route
          path="/iletisim"
          element={
            <RequireAuth>
              <Iletisim />
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/*"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route path="/Branch" element={<Branch />} />
        <Route path="/fatura" element={<Fatura />} />
        <Route path="/About" element={<About />} />
        <Route path="/Finans/*" element={<Finans />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
