import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/JWTAuthContext';
import { MessageProvider } from './context/MesssageContext';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes/router';
import { DateSelectionProvider } from './context/DateSelectionContext';
import { MenuProvider } from './context/MenuContext';
import { StrictMode } from 'react';
import { LoadingProvider } from './context/LoadingContext';

// Root elemanını oluşturun
const container = document.getElementById('root');
const root = createRoot(container);

// Uygulamayı render edin
root.render(
  <AuthProvider>
    <StrictMode>
      <MessageProvider>
        <LoadingProvider>
          <MenuProvider>
            <DateSelectionProvider>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </DateSelectionProvider>
          </MenuProvider>
        </LoadingProvider>
      </MessageProvider>
    </StrictMode>
  </AuthProvider>
);

reportWebVitals();
