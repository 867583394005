import {
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
  signOut,
  signInWithPhoneNumber,
  RecaptchaVerifier
} from 'firebase/auth';
import { auth } from '../../utils/firebase'; // Firebase yapılandırman buradan içe aktar
import axios from '../../utils/axios'; // Axios ile backend'e istek yapacağız

// Google ve Apple sağlayıcılarının tanımlanması
const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');

// Google ile giriş fonksiyonu
export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const token = await result.user.getIdToken(); // Firebase token'ı al
    console.log('Google token:', token);
    return token; // Backend'den alınan JWT token'ı döndür
  } catch (error) {
    console.error(error);
    throw error; // Hataları yönet
  }
};

// Apple ile giriş fonksiyonu
export const signInWithApple = async () => {
  try {
    const result = await signInWithPopup(auth, appleProvider);
    const token = await result.user.getIdToken(); // Firebase token'ı al
    return token; // Backend'den alınan JWT token'ı döndür
  } catch (error) {
    console.error(error);
    throw error; // Hataları yönet
  }
};

// Firebase SignOut (çıkış) işlemi
export const signOutFirebase = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error('Error signing out:', error);
    throw error; // Hataları yönet
  }
};

let recaptchaVerifier;

export const signInWithPhone = async (phoneNumber, recaptchaContainerId) => {
  try {
    // Eğer reCAPTCHA zaten render edildiyse, yeniden render etmiyoruz.
    if (!recaptchaVerifier) {
      recaptchaVerifier = new RecaptchaVerifier(
        recaptchaContainerId,
        {
          size: 'invisible', // reCAPTCHA'yı görünür yapıyoruz
          callback: (response) => {
            console.log('reCAPTCHA doğrulandı:', response);
          }
        },
        auth
      );
    }

    // reCAPTCHA'nın render edildiğinden emin oluyoruz
    await recaptchaVerifier.render();

    // Telefon numarası ile giriş
    const confirmationResult = await signInWithPhoneNumber(
      auth,
      phoneNumber,
      recaptchaVerifier
    );

    return confirmationResult;
  } catch (error) {
    console.error('Telefon numarası ile giriş hatası:', error);
    throw error;
  }
};

// Telefon doğrulama kodunu doğrulama fonksiyonu
export const verifyPhoneCode = async (confirmationResult, verificationCode) => {
  try {
    const result = await confirmationResult.confirm(verificationCode);
    const token = await result.user.getIdToken(); // Firebase token'ı al

    // Firebase token'ı backend'e gönder
    const jwtToken = await authenticateWithBackend(token);

    return jwtToken; // Backend'den alınan JWT token'ı döndür
  } catch (error) {
    console.error('Error verifying phone code:', error);
    throw error; // Hataları yönet
  }
};

// Firebase token'ı backend'e gönderip JWT token almak
export const authenticateWithBackend = async (firebaseToken) => {
  try {
    const response = await axios.post('/api/App/AuthenticateWithFirebase', {
      firebaseToken
    });
    const { token: jwtToken } = response.data.data;
    return jwtToken;
  } catch (error) {
    console.error('Error authenticating with backend:', error);
    throw error;
  }
};
