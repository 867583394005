import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { DataGrid } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import {
  Column,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  MasterDetail,
  Pager,
  Paging,
  Summary,
  TotalItem
} from 'devextreme-react/data-grid';
import '../../../assets/css/devexpress/dx.common.css';
import '../../../assets/css/devexpress/dx.material.blue.light.css';
import AracTakipDetail from './AracTakipYurtDisiDetail';
import useCargoApiContext from '../../../context/api/useCargoApiContext';
import Layout from '../../Layouts';
import WaitingSpinner from '@/components/WaitingSpinner';

function AracTakipYurtDisi() {
  const { t } = useTranslation();
  const { GetAracTakipYurtDisiAracList } = useCargoApiContext();

  const [data, setData] = useState('');

  const setAracList = (data) => {
    setData(data);
  };

  useEffect(() => {
    async function getData() {
      const result = await GetAracTakipYurtDisiAracList();
      setData(result);
    }

    getData();

    return () => {
      setData('');
    };
  }, []);

  const colorClass = {
    0: { name: t('B'), class: 'btn-secondary' },
    1: { name: t('G'), class: 'btn-danger' },
    2: { name: t('C'), class: 'btn-success' }
  };

  const renderGirisCikis = (data, field) => {
    const btnClass = colorClass[data.data[field]].class;
    const btnName = colorClass[data.data[field]].name;
    return (
      <button
        className={`btn rounded-pill text-white btn-sm py-1 w-75 gl-btn-max-width ${btnClass}`}>
        {btnName}
      </button>
    );
  };

  if (data) {
    return (
      <Layout>
        <ScrollView direction="horizontal">
          <DataGrid
            dataSource={data}
            allowColumnReordering={false}
            scrollingEnabled={false}
            columnAutoWidth={true}
            focusedRowEnabled={true}
            keyExpr="kayitNo">
            <GroupPanel visible={false} />
            <Grouping autoExpandAll={true} />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Column caption={t('AracNo')} dataField={'aracNo'} width={110} />
            <Column
              caption={t('Habur')}
              dataField={'sahaGirisCikis'}
              width={100}
              visible={true}
              cellRender={(data) => renderGirisCikis(data, 'sahaGirisCikis')}
            />
            <Column
              caption={t('Gumruk')}
              dataField={'kapiGirisCikis'}
              width={100}
              cellRender={(data) => renderGirisCikis(data, 'kapiGirisCikis')}
            />
            <Column
              caption={t('SoforAdi')}
              dataField={'soforAdi'}
              width={150}
            />
            <Column
              caption={t('Telefon')}
              dataField={'soforTelefonYurtIci'}
              width={125}
            />
            <Column caption={t('Plaka')} dataField={'plaka'} width={110} />
            <Column caption={t('TakipNo')} dataField={'takipNo'} width={100} />
            <Column
              caption={t('Tarih')}
              dataField={'tarih'}
              dataType={'date'}
              format={'dd.MM.yyyy'}
              width={115}
            />
            <Summary>
              <TotalItem column="takipNo" summaryType="count" />
            </Summary>
            <MasterDetail
              enabled={true}
              render={(e) => (
                <AracTakipDetail data={e} setAracList={setAracList} />
              )}
            />

            <Pager
              allowedPageSizes={[25, 50, 100, 1000]}
              showPageSizeSelector={true}
            />
            <Paging defaultPageSize={50} />
          </DataGrid>
        </ScrollView>
      </Layout>
    );
  } else {
    return <WaitingSpinner data={data} />;
  }
}
export default AracTakipYurtDisi;
